export function formatEmpty (v) {
  if (v == null || v == '') {
    return '-'
  }
  // 負數視為空值
  if (isNaN(v) === false && v < 0) {
    return '-'
  }
  return v
}

// 加上千分位 ,
export function formatNumberWithComma (num) {
  if (num == null || num == '' || isNaN(num) == true) {
    return num
  }
  const parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function scrollToTop (to = 0, duration = 300) {
  var start = document.body.scrollTop || document.documentElement.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20

  var animateScroll = function() {
    currentTime += increment;
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
    document.body.scrollTop = val;
    document.documentElement.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  }

  Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
  animateScroll()
}