import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        keyword: '',
        moduleType: 'chart'
    },
    mutations: {
        mutationKeyword(state, keyword) {
            state.keyword = keyword;
        },
        mutationModuleType(state, type = 'chart') {
            state.moduleType = type;
        }
    },
    actions: {},
    modules: {}
});
