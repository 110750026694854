import Vue from 'vue';
import VueRouter, {} from 'vue-router';
import middleware from './middleware';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        // redirect: { name: 'home' },
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
            },
            {
                path: '/demo',
                redirect: { name: 'home' },
                name: 'demo',
                component: () => import(/* webpackChunkName: "index" */ '@/views/demo/Demo.vue'),
                children: [
                    {
                        path: '/demo/list/',
                        name: 'demo-list',
                        component: () => import(/* webpackChunkName: "demo-list" */ '@/views/demo/list/List.vue'),
                    },
                    {
                        path: '/demo/modules/',
                        name: 'demo-modules',
                        component: () => import(/* webpackChunkName: "demo-modules" */ '@/views/demo/modules/Modules.vue'),
                        children: [
                            {
                                path: '/demo/modules/:moduleName',
                                name: 'demo-modules-modulename',
                                component: () => import(/* webpackChunkName: "demo-modules-modulename" */ '@/views/demo/modules/_moduleName/ModuleName.vue'),
                                props: true,
                            },
                        ]
                    },
                    {
                        path: '/guide',
                        name: 'guide',
                        component: () => import(/* webpackChunkName: "guide" */ '@/views/Guide.vue'),
                    },
                    {
                        path: '/versions',
                        name: 'versions',
                        component: () => import(/* webpackChunkName: "versions" */ '@/views/Versions.vue'),
                    },
                    {
                        path: '/developer',
                        name: 'developer',
                        component: () => import(/* webpackChunkName: "developer" */ '@/views/Developer.vue'),
                    },
                ]
            },
        ]
    },
    {
        path: '/fullscreen',
        name: 'fullscreen',
        component: () => import(/* webpackChunkName: "fullscreen" */ '@/views/fullscreen/Test.vue'),
        children: [
            {
                path: '/fullscreen/:moduleName',
                name: 'fullscreen-modulename',
                component: () => import(/* webpackChunkName: "fullscreen-modulename" */ '@/views/fullscreen/_moduleName/ModuleName.vue'),
                props: true
            }
        ]
    },
    // {
    //   path: '/fullscreen/:moduleName',
    //   name: 'fullscreen',
    //   component: () => import(/* webpackChunkName: "demo" */ '@/views/fullscreen/Test.vue'),
    // }
    // 測試用
    // {
    //   // alias: ['/widgets'],
    //   path: '/widgets/Bar',
    //   name: 'widgets-bar',
    //   component: () => import(/* webpackChunkName: "Widgets" */ '@/views/widgets/Bar.vue'),
    // },
    // {
    //   // alias: ['/widgets'],
    //   path: '/ChartDateScale',
    //   name: 'chart-date-scale',
    //   component: () => import(/* webpackChunkName: "ChartDateScale" */ '@/core_legacy/modules/chartDateHistogram/ChartDateScale.vue'),
    // },
    // {
    //   // alias: ['/widgets'],
    //   path: '/CardWidget',
    //   name: 'card-widget',
    //   component: () => import(/* webpackChunkName: "ContainerWidget" */ '@/core_legacy/vueComponents/cards/CardWidget.vue'),
    // },
    // {
    //   // alias: ['/widgets'],
    //   path: '/LegendCheckList',
    //   name: 'legend-check-list',
    //   component: () => import(/* webpackChunkName: "LegendCheckList" */ '@/core_legacy/vueComponents/private/LegendCheckList.vue'),
    // },
    // {
    //   // alias: ['/widgets'],
    //   path: '/ChartDateScaleTemp',
    //   name: 'chart-date-scale-temp',
    //   component: () => import(/* webpackChunkName: "ChartDateScaleTemp" */ '@/core_legacy/vueComponents/modules/ChartDateScaleTemp.vue'),
    // },
];
const router = new VueRouter({
    mode: 'history',
    //@ts-ignore
    base: process.env.BASE_URL,
    routes
});
router.beforeEach(middleware);
export default router;
