import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-TW';
import 'element-ui/lib/theme-chalk/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/assets/scss/style.scss';
import Parallax from 'vue-parallaxy';
import vClickOutside from 'v-click-outside';
// @ts-ignore
library.add(fas, far);
// dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Parallax', Parallax);
Vue.use(ElementUI, { locale });
Vue.use(vClickOutside);
Vue.config.productionTip = false;
// @Q@ 因為 vue-component-sandbox 依賴的 VueJsonPretty 套件很容易因為塞入的 json格式不合法（含有物件）在 console 噴 warn
// 暫想不到解法所以就讓他不顯示 warn 警告
Vue.config.warnHandler = (err, vm, info) => {
    return false;
};
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
